import { hydrate } from "@/utils/form";

export default class Form {
    id = null;
    name = null;
    fields = [];
    club = JSON.parse(localStorage.getItem('selectedClub'));

    constructor(object = null) {
        if (object) {
            this.serialize(object);
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
}
